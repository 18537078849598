function getData(url, data){
    $.ajax({
        url: url,
        data: data,
        dataType: "json",
        method: "GET",
        success: function(data){
            return data;
        },
        error: function(data){
        },
        complete: function(data){
        }
    });
}

function postData(url, data, callback){
    $.ajax({
        url: url,
        data: data,
        dataType: "json",
        method: "POST",
        crossDomain: true,
        success: callback
    });
}

function updateData(url, data){
    $.ajax({
        url: url,
        data: data,
        dataType: "json",
        method: "PUT",
        crossDomain: true,
        statusCode:{
            401: function(){
            }
        },
        success: function(msg){
        },
        error: function(msg){
        },
        complete: function(msg){
        }
    });
}
