$('#btnSignIn').click(function(event){
    event.preventDefault();
    var data = $("#frmSignIn").serialize();
    var url = site_url+"/authenticate";
    toastr.remove();
    toastr.info('Please wait, we are going to authenticate you...');
    $('#btnSignIn').hide();
    postData(url, data, signInCallback);
});
function signInCallback(data, textStatus, xhr){
    toastr.remove();
    $('#btnSignIn').show();
    if(data.error == 0){
        toastr.success(data.label);
        toastr.info("Please wait, we are going to redirect you...");
        location = "/dashboard";
    }
    else if(data.error == 1){
        toastr.error(data.label);
    }
}